
export const HowToPlay = () => {
    return (
        <div className="AboutText">
            <h1>How to Play Reversi</h1>
            <p>Reversi is a strategy board game for two players (black and white), played on an 8&times;8 board.</p>
            <h2>Objective</h2>
            <p>The player with the most pieces on the board at the end of the game wins.</p>
            <h2>Rules</h2>
            <ul>
                <li>Black moves first.</li>
                <li>Players must alternate turns.</li>
                <li>On each turn, a player places one piece on the board in a valid square.</li>
                <ul>
                    <li>The square must be empty.</li>
                    <li>The square must be adjacent to an opponent's piece (including diagonals).</li>
                    <li>Following a straight line from the square, over the opponent's piece, there must be a piece of the player's color.</li>
                </ul>
                <li>After playing a piece, follow a line in each direction (including diagonals) and flip piece to the player's color where the line ends in a piece of the player's color.</li>
                <li>If there are any valid moves, one must be played.</li>
                <li>A player with no valid moves must pass.</li>
            </ul>
            <p>The game ends when the board is full or when both players consecutively pass their turns.
                The player with the most pieces on the board at the end of the game wins.</p>

            <p>For more details see <a href="https://en.wikipedia.org/wiki/Reversi" target="_blank" rel="nofollow noreferrer">Reversi - Wikipedia</a></p>
        </div>
    );
};

export default HowToPlay;
