import React, { useEffect } from 'react';
import Board from './Board';
import BoardDisplaySettingsDesigner from './BoardDisplaySettingsDesigner';
import BoardDisplaySettingsModel from '../models/BoardDisplaySettingsModel';
import BoardModel from '../models/BoardModel';
import PieceModel from '../models/PieceModel';

export const EditBoard = () => {
    const [boardDisplaySettings, setBoardDisplaySettings] = React.useState(new BoardDisplaySettingsModel());
    const [board, setBoard] = React.useState(BoardModel.startBoard());
    const [piece, setPiece] = React.useState(PieceModel.Black);

    const handleSquareClick = (squareName: string) => {
        let localUpdateBoard = BoardModel.clearAllValidMoves(
            BoardModel.toggleSquare(board, squareName));
        setBoard(localUpdateBoard);
    }

    let boardText = BoardModel.getBoardText(board);
    useEffect(() => {
        async function applyServerBoard() {
            let updatedBoard = await BoardModel.getServerBoard(boardText, piece);
            setBoard(updatedBoard);
        };
        applyServerBoard();
    }, [boardText, piece])

    return (
        <div>
            <BoardDisplaySettingsDesigner
                boardDisplaySettings={boardDisplaySettings}
                setBoardDisplaySettings={setBoardDisplaySettings}></BoardDisplaySettingsDesigner>
            <Board
                board={board}
                boardDisplaySettings={boardDisplaySettings}
                squareClickHandler={handleSquareClick}
                currentPlayer={piece}></Board>
            <hr />
            Showing valid moves for
            <select name="piece" onChange={e => setPiece(PieceModel.parse(e.target.value))}>
                {PieceModel
                    .all()
                    .map(p => <option selected={p === piece} value={p} key={p}>{p}</option>)
                }
            </select>
        </div>
    );
};

export default EditBoard;
