import ActionButtonList from './ActionButtonList';
import NavigateActionButton from './NavigateActionButton';
import NewGameButton from './NewGameButton';

export const MainPage = () => {

    return (
        <div className="App">
            <h1>Reversi</h1>
            <ActionButtonList>
                <NavigateActionButton to="board/edit">Edit Board</NavigateActionButton>
                <NewGameButton />
                <NavigateActionButton to="about/howtoplay">How to Play</NavigateActionButton>
            </ActionButtonList>
        </div>
    );
};

export default MainPage;