import Service from "../utils/Service";
import PieceModel from "./PieceModel";

interface ShareDetailsModel {
    title: string;
    text: string;
    url: string;
}

namespace ShareDetailsModel {

    export function forPlayer(gameId: string, sharePlayer?: PieceModel) {
        return {
            title: "Play Reversi With Me",
            text: `It's your turn to play as ${PieceModel.displayAny(sharePlayer)} in a game of Reversi!`,
            url: Service.playGame.url(gameId, sharePlayer)
        }
    };

}

export default ShareDetailsModel;