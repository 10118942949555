import BoardDisplaySettingsModel from '../models/BoardDisplaySettingsModel';
import PieceModel from '../models/PieceModel';
import SquareModel from '../models/SquareModel';

interface IProps {
    boardDisplaySettings: BoardDisplaySettingsModel,
    square: SquareModel,
    squareClickHandler: (squareName: string) => void,
}

export const Square = ({
    boardDisplaySettings = new BoardDisplaySettingsModel(),
    square,
    squareClickHandler = e => { } }: IProps) => {
    return (<td style={{ backgroundColor: boardDisplaySettings.SquareColor }}
        key={square.name}
        data-square-name={square.name}
        onClick={event => squareClickHandler(event.currentTarget.attributes.getNamedItem("data-square-name")!.value)}>
        {PieceModel.hasPiece(square.piece) && <span className={'piece ' + square.piece} />}
        {boardDisplaySettings.ShowValidMoves && square.isValidMove && <span className="validMove" />}
        {boardDisplaySettings.HighlightLastMove && square.highlightSquare && <span className="highlightSquare" />}
    </td>
    );
};

export default Square;