import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from 'react';
import './sass/app.scss';

import EditBoard from './components/EditBoard';
import MainPage from './components/MainPage';
import Layout from './Layout';
import PlayGame from "./components/PlayGame";
import GameSettingsDesigner from "./components/GameSettingsDesigner";
import HowToPlay from "./components/HowToPlay";
import About from "./components/About";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<MainPage />} />
                    <Route path="board/edit" element={<EditBoard />} />
                    <Route path="play/" element={<GameSettingsDesigner />} />
                    <Route path="play/:gameId/:player?" element={<PlayGame />} />
                    <Route path="about/howtoplay" element={<HowToPlay />} />"
                    <Route path="about" element={<About />} />"
                    <Route path="*" element={<h1>NOT FOUND</h1>} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
