enum PieceModel {
    White = "white",
    Black = "black",
    None = "none",
}

namespace PieceModel {

    export function all(): PieceModel[] {
        //This doesn't seem to work:
        //Error: expect(received).toEqual(expected) // deep equality

        //    - Expected - 3
        //    + Received + 9

        //Array[
        //    -   "white",
        //    -   "black",
        //    -   "none",
        //    +   "White",
        //    +   "Black",
        //    +   "None",
        //    +   "all",
        //    +   "parse",
        //    +   "display",
        //    +   "cycle",
        //    +   "hasPiece",
        //    +   "toCharString",
        // https://stackoverflow.com/questions/48768774/how-to-get-all-the-values-of-an-enum-with-typescript
        //return Object.keys(PieceModel).filter((item) => {
        //    return isNaN(Number(item));
        //}).map(s=>(s as PieceModel));
        return [PieceModel.White, PieceModel.Black, PieceModel.None];
    }
    export function parse(value: string): PieceModel {
        if (match(PieceModel.White, value)) {
            return PieceModel.White;
        }
        else if (match(PieceModel.Black, value)) {
            return PieceModel.Black;
        }
        else {
            return PieceModel.None;
        }
    }

    export function match(model: PieceModel, value: string): boolean {
        if (value) {
            value = value.toLowerCase();
            return value.toLowerCase() === model.toLowerCase()
                || value === toCharString(model).toLowerCase();
        } else {
            return false;
        }
    }

    export function display(piece: PieceModel): string {
        switch (piece) {
            case PieceModel.White:
                return "White";
            case PieceModel.Black:
                return "Black";
            case PieceModel.None:
                return "None";
            default:
                const exhaustiveCheck: never = piece;
                throw new Error(`Unhandled PieceModel value: ${exhaustiveCheck}`);
        }
    }
    export function displayAny(piece?: PieceModel): string {
        return undefined === piece ? "Any" : display(piece);
    }

    export function cycle(piece: PieceModel): PieceModel {
        switch (piece) {
            case PieceModel.White:
                return PieceModel.None;
            case PieceModel.Black:
                return PieceModel.White;
            case PieceModel.None:
                return PieceModel.Black;
            default:
                const exhaustiveCheck: never = piece;
                throw new Error(`Unhandled PieceModel value: ${exhaustiveCheck}`);
        }
    }
    export function flip(piece: PieceModel): PieceModel {
        switch (piece) {
            case PieceModel.White:
                return PieceModel.Black;
            case PieceModel.Black:
                return PieceModel.White;
            case PieceModel.None:
                return PieceModel.None;
            default:
                const exhaustiveCheck: never = piece;
                throw new Error(`Unhandled PieceModel value: ${exhaustiveCheck}`);
        }
    }

    export function hasPiece(model: PieceModel): boolean {
        return model !== PieceModel.None;
    }

    export function toCharString(piece: PieceModel): string {
        switch (piece) {
            case PieceModel.White:
                return "O";
            case PieceModel.Black:
                return "X";
            case PieceModel.None:
                return "-";
            default:
                const exhaustiveCheck: never = piece;
                throw new Error(`Unhandled PieceModel value: ${exhaustiveCheck}`);
        }
    }

}

export default PieceModel;