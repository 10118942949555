import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BoardDisplaySettingsModel from '../models/BoardDisplaySettingsModel';
import BoardModel from '../models/BoardModel';
import GameStateModel from '../models/GameStateModel';
import PieceModel from '../models/PieceModel';
import ShareDetailsModel from '../models/ShareDetailsModel';
import Service from '../utils/Service';
import ActionButton from './ActionButton';
import Board from './Board';
import CurrentPlayerDisplay from './CurrentPlayerDisplay';

interface IParams {
    gameId: string;
    player?: PieceModel;
}
export const PlayGame = () => {
    const { gameId, player } = useParams<keyof IParams>() as IParams;
    const [gameState, setGameState] = useState<GameStateModel | null>(null);
    const [moveList, setMoveList] = useState<string>("");

    const shareDetails = (sharePlayer?: PieceModel) => ShareDetailsModel.forPlayer(gameId, sharePlayer);

    const handleSquareClick = (squareName: string) => {

        //TODO: This may make the back-end changes redundant
        if (!isCurrentPlayer) {
            return;
        }
        //TODO: validate move, right now this depends on the server ignoring invalid moves

        //HACK: remove parentheses and commas from square name because the server UI
        // can't handle the "display format"
        squareName = squareName.replaceAll(/[(),]/g, '');
        GameStateModel.makeMove(gameId, squareName, player)
            .then(updatedState => {
                setGameState(updatedState);
            })
            .catch(e => {
                alert(e);
            });
    };

    useEffect(() => {
        async function watchGameState(signal: AbortSignal) {
            if (gameState && gameState.identity !== gameId) {
                setGameState(null);
            }
            const awaitVersion = (gameState) ? gameState.version + 1 : 0;
            let newGameState = await GameStateModel.waitForGameUpdate(gameId, awaitVersion, signal);

            if (newGameState) {
                setGameState(newGameState);
            }
        }
        if (gameId) {
            let abortController = new AbortController();
            watchGameState(abortController.signal);

            return () => abortController.abort();
        }
    }, [gameId, gameState]);

    useEffect(() => {
        async function getMoveList(version: number) {
            let moveList = await (await fetch(Service.game.moveList(gameId, version)))
                .text();
            setMoveList(moveList);
        };
        if (gameState) {
            getMoveList(gameState.version);
        }
    }, [gameState, gameId]);

    var isCurrentPlayer = gameState !== null
        && (player === undefined || gameState.currentPlayer === player);

    var boardDisplaySettings = new BoardDisplaySettingsModel();
    boardDisplaySettings.ShowValidMoves = isCurrentPlayer;

    return (gameState) ? <Fragment key={gameId}>
        <Board
            board={gameState.board}
            boardDisplaySettings={boardDisplaySettings}
            squareClickHandler={handleSquareClick}
            currentPlayer={gameState.currentPlayer}
            isGameOver={GameStateModel.isGameOver(gameState)}
            isForcedPass={isCurrentPlayer && gameState.legalMoves.every(m => m === "Pa") && gameState.legalMoves.length === 1}
        />
        <CurrentPlayerDisplay gameState={gameState} player={player} />
        <h2>Legal Moves</h2>
        <ul>
            {gameState?.legalMoves?.map(
                (m, i) =>
                    <li key={m}>
                        <button data-square-name={m} onClick={() => handleSquareClick(m)}>{m}</button>
                    </li>)}
        </ul>
        <h2>Move History</h2>
        <img width="400px" height="200px" alt="Move History" src={Service.game.scoreGraphUrl(gameId, gameState.version)} />
        <br />
        <input readOnly className="moveListBox" type="text" value={moveList}></input>
        <br />
        <input readOnly className="currentBoardText" type="text" value={BoardModel.getBoardText(gameState.board)}></input>

        <ActionButton onClick={() => fetch(Service.agent.requestMove(
            "Random",
            BoardModel.getBoardText(gameState.board),
            PieceModel.toCharString(gameState.currentPlayer),
            Service.game.makeMove(gameState.identity, "{move}", player)))}>Request AI Move</ActionButton>

        <ActionButton onClick={() => GameStateModel.requestAgent(
            gameState,
            gameState.currentPlayer,
            "Random")}>Request AI Opponent</ActionButton>

        {
            [PieceModel.Black, PieceModel.White, undefined].map(p =>
                <Fragment key={p}>
                    <ActionButton onClick={() => navigator.share(shareDetails(p))}>{`Share Game Link for ${PieceModel.displayAny(p)}`}</ActionButton>
                    <ActionButton onClick={() => window.open(shareDetails(PieceModel.Black).url, "_blank")}>{`Open New Window for ${PieceModel.displayAny(p)}`}</ActionButton>
                    <ActionButton onClick={() => window.location.replace(shareDetails(p).url)}>{`Change to ${PieceModel.displayAny(p)}`}</ActionButton>
                </Fragment>)
        }

    </Fragment>
        : <><h1>No Game</h1></>;
};

export default PlayGame;