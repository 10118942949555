import { useEffect, useState } from 'react';
import Service from '../utils/Service';

export const About = () => {
    const [version, setVersion] = useState("loading...");
    const [serverTimestamp, setServerTimestamp] = useState("loading...");
    const [serverVersion, setServerVersion] = useState("loading...");

    useEffect(
        () => {
            fetch(Service.about.version(), { method: 'GET' })
                .then(r => r.text())
                .then(t => setVersion(t))
        });

    useEffect(
        () => {
            fetch(Service.about.timestamp(), { method: 'GET' })
                .then(r => r.text())
                .then(t => setServerTimestamp(t))
        });

    useEffect(
        () => {
            fetch(Service.about.serverVersion(), { method: 'GET' })
                .then(r => r.text())
                .then(t => setServerVersion(t))
        });

    const data = [
        { name: "API Location", value: Service.apiUrl },
        { name: "API Version", value: version },
        { name: "Server Timestamp", value: serverTimestamp },
        { name: "Server Version", value: serverVersion },
    ];

    return (
        <div>
            <h1>About Reversi</h1>
            <p>Reversi is a strategy board game for two players, played on an 8&times;8 uncheckered board.</p>
            <h1>Technical Details</h1>
            <table>
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Value</th>
                    </tr></thead>
                <tbody>
                    {data.map(d =>
                        <tr key={d.name}>
                            <th scope="row">{d.name}</th>
                            <td>{d.value}</td>
                        </tr>)}
                </tbody>
            </table>
        </div>
    );
};

export default About;
