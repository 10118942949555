import PieceModel from "../models/PieceModel";

const Service = {
    uiUrl: window.location.origin + '/',
    apiUrl: process.env.REACT_APP_API_URL,
    game: {
        url: () => `${Service.apiUrl}Game`,
        createNewGame: () => Service.game.url(),
        game: (gameId: string) => `${Service.game.url()}/${gameId}`,
        gameVersion: (gameId: string, version: number) => `${Service.game.game(gameId)}/${version}`,
        makeMove: (gameId: string, move: string, player?: PieceModel) => `${Service.game.game(gameId)}/${move}/${player} `,
        scoreGraphUrl: (gameId: string, version: number) => `${Service.game.game(gameId)}/ScoreGraph?v=${version}`,
        moveList: (gameId: string, version: number) => `${Service.game.game(gameId)}/MoveList?v=${version}`,
    },
    about: {
        url: () => `${Service.apiUrl}about/`,
        version: () => `${Service.about.url()}version`,
        timestamp: () => `${Service.about.url()}timestamp`,
        serverVersion: () => `${Service.about.url()}server`,
    },
    agent: {
        url: () => `${Service.apiUrl}agent/`,
        requestMove: (agentName: string, board: string, piece: string, responseUrlFormat: string) => `${Service.agent.url()}requestMove?agentName=${encodeURIComponent(agentName)}&board=${encodeURIComponent(board)}&piece=${encodeURIComponent(piece)}&responseUrlFormat=${encodeURIComponent(responseUrlFormat)}`,
        requestGame: (agentName: string, game: string, piece: string) => `${Service.agent.url()}requestGame?agentName=${encodeURIComponent(agentName)}&game=${encodeURIComponent(game)}&piece=${encodeURIComponent(piece)}`,
        agents: () => `${Service.agent.url()}agents`,
    },
    playGame: {
        urlFragment: (gameId: string, player?: PieceModel) => player === undefined ? `/play/${gameId}` : `/play/${gameId}/${player}`,
        url: (gameId: string, player?: PieceModel) => `${window.location.origin}${Service.playGame.urlFragment(gameId, player)}`,
    }
};
export default Service;
