import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GameSettingsModel from '../models/GameSettingsModel';
import PieceModel from '../models/PieceModel';
import ActionButton from './ActionButton';
import PlayerPicker from './PlayerPicker';

export const PlayGame = () => {
    const navigate = useNavigate();

    const [settings, setSettings] = useState<GameSettingsModel>(GameSettingsModel.empty());

    const handleCreateGameClick = () => GameSettingsModel.startInteractive(settings, window.navigator, navigate)
        

    const setAgent = (player: PieceModel, agent: string) =>
        setSettings(GameSettingsModel.withPlayer(settings, player, agent));

    return <div>
        <PlayerPicker player={PieceModel.White} onChange={setAgent} />
        <PlayerPicker player={PieceModel.Black} onChange={setAgent} />
        <ActionButton onClick={handleCreateGameClick}>Create Game</ActionButton>
    </div>
};

export default PlayGame;