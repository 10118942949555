import React from 'react';
import { To, useNavigate } from 'react-router-dom';
import ActionButton from './ActionButton';

interface IProps {
    children: string | JSX.Element;
    to: To;
};

export const NavigateActionButton = ({ children, to }: IProps) => {
    const navigate = useNavigate();
    return (
        <ActionButton onClick={() => navigate(to)}>
            {children}
        </ActionButton>
    );
};

export default NavigateActionButton;
