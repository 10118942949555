import React from 'react';
import BoardDisplaySettingsModel from '../models/BoardDisplaySettingsModel';

interface IProps {
    boardDisplaySettings?: BoardDisplaySettingsModel,
    setBoardDisplaySettings?: React.Dispatch<React.SetStateAction<BoardDisplaySettingsModel>>
};

export const BoardDisplaySettingsDesigner = ({
    boardDisplaySettings = new BoardDisplaySettingsModel(),
    setBoardDisplaySettings = s => { }
}: IProps) => {
    return <form>{Object.getOwnPropertyNames(
        new BoardDisplaySettingsModel()).map(prop => {
            let value = boardDisplaySettings[prop as keyof BoardDisplaySettingsModel];
            const useCheckbox = typeof value === 'boolean';

            const useColorPicker = prop === 'SquareColor';

            const applyChange = (newValue: any) => {
                setBoardDisplaySettings(
                    boardDisplaySettings.With(b => (b as { [key: string]: any })[prop] = newValue));
            };

            return <React.Fragment key={prop}>
                <label htmlFor={prop}>{prop}: </label>
                {useCheckbox &&
                    <input
                        type="checkbox"
                        name={prop}
                        id={prop}
                        checked={value as boolean}
                        onChange={event => applyChange(event.target.checked)}
                    />
                }
                {!useCheckbox &&
                    <input
                        type={useColorPicker ? 'color' : 'text'}
                        name={prop}
                        id={prop}
                        value={value as string}
                        onChange={event => applyChange(event.target.value)}
                    />
                }
                <br />
            </React.Fragment>
        }
        )}
    </form>
};

export default BoardDisplaySettingsDesigner;