import BoardDisplaySettingsModel from '../models/BoardDisplaySettingsModel';
import BoardModel from '../models/BoardModel';
import Square from './Square';

interface IProps {
    boardDisplaySettings: BoardDisplaySettingsModel,
    rowNumber: number,
    board: BoardModel,
    squareClickHandler: (squareName: string) => void,
};

export const BoardRow = ({
    boardDisplaySettings = new BoardDisplaySettingsModel(),
    rowNumber,
    board = BoardModel.emptyBoard(),
    squareClickHandler = e => { },
}: IProps) => {
    return (
        <tr>
            {boardDisplaySettings.ShowLeftHeader && <th scope="row">{rowNumber}</th>}

            {BoardModel
                .squaresByRow(board, rowNumber)
                .map(cell => <Square
                    key={cell.name}
                    boardDisplaySettings={boardDisplaySettings}
                    square={cell}
                    squareClickHandler={squareClickHandler}
                />)}

            {boardDisplaySettings.ShowRightHeader && <th scope="row">{rowNumber}</th>}
        </tr>
    );
};

export default BoardRow;