import React from 'react';

function useOnClickOutside(
    ref: React.RefObject<any>,
    handler: () => void,
    excludedClassNames: string = "") {
    React.useEffect(() => {
        const listener = (event: TouchEvent | MouseEvent) => {
            const eventWasInExcludedClass = Array.from(document.getElementsByClassName(excludedClassNames))
                .some(e => e.contains(event.target as Node));
            if (eventWasInExcludedClass
                || !ref
                || !ref.current
                || ref.current.contains(event.target as Node)) {
                return;
            }
            handler();
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler, excludedClassNames]);
}

export default useOnClickOutside;