import { useNavigate } from "react-router-dom";
import GameStateModel from '../models/GameStateModel';
import ActionButton from "./ActionButton";

export const NewGameButton = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        GameStateModel
            .createNewGame()
            .then(game => navigate('/play/' + game.identity));
    };

    return (<ActionButton onClick={handleClick}>New Game</ActionButton>)
};

export default NewGameButton;