import Service from '../utils/Service';

interface AgentDescriptionModel {
    name: string;
    displayName: string;
}

namespace AgentDescriptionModel {

    export function getAllAgents(): Promise<AgentDescriptionModel[]> {
        let url = Service.agent.agents();
        return fetch(url, { method: 'GET' })
            .then(r => r.json());
    }
    export function empty(): AgentDescriptionModel[] {
        return [];
    }

}

export default AgentDescriptionModel;
