import { useState, useRef } from "react"
import { Outlet } from "react-router-dom";
import MenuButton from "./components/MenuButton";
import Menu from "./components/Menu";
import useOnClickOutside from "./effects/OnClickOutsideEffect";

const Layout = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const menuButtonRef = useRef<HTMLButtonElement>(null);

    useOnClickOutside(menuRef, () => { if (menuOpen) { setMenuOpen(false); } }, "menu-button-container");

    return (
        <>
            <div className="header">
                <img src="/logo128.png" alt="Icon" />
                <h1 className="title">Reversi.Game</h1>
                <span className="menu-button-container">
                    <MenuButton
                        handleMenuClick={() => setMenuOpen(!menuOpen)}
                        menuOpen={menuOpen}
                        ref={menuButtonRef} />
                </span>
            </div>
            <Menu
                menuOpen={menuOpen}
                handleMenuItemClick={() => setMenuOpen(false)}
                ref={menuRef}></Menu>
            <div className="content">
                <Outlet />
            </div>
        </>
    );
};

export default Layout;
