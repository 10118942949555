import Service from '../utils/Service';
import PieceModel from './PieceModel';
import SquareModel from './SquareModel';

interface BoardModel {
    squares: SquareModel[];
}

namespace BoardModel {
    export function columnNames(): string[] {
        return "ABCDEFGH".split("");
    }

    export function rowNumbers(): number[] {
        return [1, 2, 3, 4, 5, 6, 7, 8];
    }

    export function allSquareNames(): string[] {
        let ret: string[] = [];
        rowNumbers().forEach(row => {
            columnNames().forEach(col => {
                ret.push(col + row);
            });
        });
        return ret;
    }

    export function getBoardSquare(board: BoardModel, square: string): SquareModel {
        return board.squares.find(sq => SquareModel.matchSquareName(sq, square))
            ?? { name: square, piece: PieceModel.None, isValidMove: false, highlightSquare: false };
    }

    export function bindCellLookup(board: BoardModel, rowNumber: number): (col: string) => SquareModel {
        return function (col: string): SquareModel {
            return getBoardSquare(board, col + rowNumber);
        };
    }

    export function squaresByRow(board: BoardModel, rowNumber: number): SquareModel[] {
        return columnNames().map(col => getBoardSquare(board, col + rowNumber));
    }

    export function toggleSquare(board: BoardModel, square: string): BoardModel {
        var updatedSquare = structuredClone(getBoardSquare(board, square));
        updatedSquare.isValidMove = false;
        updatedSquare.piece = PieceModel.cycle(updatedSquare.piece);
        var updatedSquares = board.squares.filter(sq => !SquareModel.matchSquareName(sq, square));
        updatedSquares.push(updatedSquare);
        return {
            squares: updatedSquares
        };
    }

    export function clearAllValidMoves(board: BoardModel): BoardModel {
        let updatedSquares = board.squares.map(sq => {
            return { ...sq, isValidMove: false };
        });
        return {
            squares: updatedSquares
        };
    }

    export function getServerBoard(boardText: string, piece: PieceModel): Promise<BoardModel> {
        //HACK: the URL should probably not require the char string, but does for now
        let url = Service.apiUrl + 'Board/' + boardText + '/' + PieceModel.toCharString(piece);
        return fetch(url, { method: 'GET' })
            .then(r => r.json() as Promise<BoardModel>);
    }

    export function getBoardText(board: BoardModel): string {
        return allSquareNames()
            .map(sq => getBoardSquare(board, sq))
            .map(sq => PieceModel.toCharString(sq.piece))
            .join("");
    }

    export function emptyBoard(): BoardModel {
        let board: BoardModel =
        {
            squares: []
        };
        return board;
    }

    export function startBoard(): BoardModel {
        let board: BoardModel =
        {
            squares:
                [
                    { name: "D4", piece: PieceModel.White, isValidMove: false, highlightSquare: false },
                    { name: "E5", piece: PieceModel.White, isValidMove: false, highlightSquare: false },
                    { name: "D5", piece: PieceModel.Black, isValidMove: false, highlightSquare: false },
                    { name: "E4", piece: PieceModel.Black, isValidMove: false, highlightSquare: false },
                    { name: "D3", piece: PieceModel.None, isValidMove: true, highlightSquare: false },
                    { name: "C4", piece: PieceModel.None, isValidMove: true, highlightSquare: false },
                    { name: "E6", piece: PieceModel.None, isValidMove: true, highlightSquare: false },
                    { name: "F5", piece: PieceModel.None, isValidMove: true, highlightSquare: false },
                ]
        };
        return board;
    }
}
export default BoardModel;