import GameStateModel from "../models/GameStateModel";
import PieceModel from "../models/PieceModel";
import '../sass/components/CurrentPlayerDisplay.scss';

interface IProps {
    gameState: GameStateModel;
    player?: PieceModel;
}

export const CurrentPlayerDisplay = ({
    gameState,
    player
}: IProps) => {

    var isCurrentPlayer = gameState !== null
        && (player === undefined || gameState.currentPlayer === player);

    const countPieces = (pieceToCount: PieceModel) =>
        gameState.board.squares.reduce(
            (count, sq) => count + (sq.piece === pieceToCount ? 1 : 0),
            0);

    return (
        <div className="currentPlayerDisplay">
            <div className={`black ${gameState.currentPlayer === PieceModel.Black ? 'active' : ''}`}>
                Black: {countPieces(PieceModel.Black)}
            </div>
            <div className={`white ${gameState.currentPlayer === PieceModel.White ? 'active' : ''}`}>
                White: {countPieces(PieceModel.White)}
            </div>
            <div className='playerTurn'>{
                GameStateModel.isGameOver(gameState)
                    ? 'Game Over'
                    : isCurrentPlayer
                        ? "It's your turn"
                        : 'Waiting for opponent'
            }</div>
        </div>
    );
};

export default CurrentPlayerDisplay;