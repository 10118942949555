import { NavigateFunction } from "react-router-dom";
import Service from "../utils/Service";
import GameStateModel from "./GameStateModel";
import PieceModel from "./PieceModel";
import ShareDetailsModel from "./ShareDetailsModel";

interface GameSettingsModel {
    whitePlayer: string;
    blackPlayer: string;
}

namespace GameSettingsModel {
    export const HumanAgentName = "Human";
    export const InvitationAgentName = "Invitation";

    export function empty(): GameSettingsModel {
        return { whitePlayer: HumanAgentName, blackPlayer: HumanAgentName };
    }

    export async function startInteractive(
        model: GameSettingsModel,
        navigator: Navigator,
        navigate: NavigateFunction
    ): Promise<GameStateModel> {
        let game = await GameStateModel.createNewGame();
        if (isAgent(model.whitePlayer)) {
            await GameStateModel.requestAgent(game, PieceModel.White, model.whitePlayer);
        }
        else if (model.whitePlayer === InvitationAgentName) {
            let details = ShareDetailsModel.forPlayer(game.identity, PieceModel.White);
            await navigator.share(details);
        }

        if (isAgent(model.blackPlayer)) {
            await GameStateModel.requestAgent(game, PieceModel.Black, model.blackPlayer);
        }
        else if (model.blackPlayer === InvitationAgentName) {
            let details = ShareDetailsModel.forPlayer(game.identity, PieceModel.Black);
            await navigator.share(details);
        }

        await navigate(Service.playGame.urlFragment(game.identity, localPlayer(model)));
        return game;
    }

    export function localPlayer(model: GameSettingsModel): PieceModel | undefined {
        if (isAgent(model.whitePlayer)) {
            if (isAgent(model.blackPlayer)) {
                return PieceModel.None;
            } else {
                return PieceModel.Black
            }
        }
        else {
            if (isAgent(model.blackPlayer)) {
                return PieceModel.White;
            } else {
                return undefined;
            }
        }
    }

    export function withPlayer(model: GameSettingsModel, player: PieceModel, agentName: string): GameSettingsModel {
        switch (player) {
            case PieceModel.White:
                return { ...model, whitePlayer: agentName };
            case PieceModel.Black:
                return { ...model, blackPlayer: agentName };
            default:
                return model;
        }
    };

    function isAgent(player: string): boolean {
        return player !== HumanAgentName
            && player !== InvitationAgentName;
    };
}

export default GameSettingsModel;