import React, { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import '../sass/components/Menu.scss'

interface IProps {
    menuOpen: boolean,
    handleMenuItemClick?: React.MouseEventHandler,
}

export const Menu = forwardRef<HTMLDivElement, IProps>((
    {
        menuOpen = false,
        handleMenuItemClick = () => { }
    },
    ref) => {
    return (<>{menuOpen && (
        <div className="menu-popup" ref={ref}>
            <nav>
                <ul>
                    <li><NavLink onClick={handleMenuItemClick} to="/">Home</NavLink></li>
                    <li><NavLink onClick={handleMenuItemClick} to="/board/edit">Edit Board</NavLink></li>
                    <li><NavLink onClick={handleMenuItemClick} to="/play">Play Game</NavLink></li>
                    <li><NavLink onClick={handleMenuItemClick} to="/about/howtoplay">How to Play</NavLink></li>
                    <li><NavLink onClick={handleMenuItemClick} to="/about">About</NavLink></li>
                </ul>
            </nav>
        </div>
    )}</>
    );
});

export default Menu;