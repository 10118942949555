import { useEffect, useState } from 'react';
import AgentDescriptionModel from '../models/AgentDescriptionModel';
import GameSettingsModel from '../models/GameSettingsModel';
import PieceModel from '../models/PieceModel';

interface IProps {
    player: PieceModel,
    includeHuman?: boolean,
    includeInvitation?: boolean,
    onChange: (player: PieceModel, agentName: string) => void
}

export const PlayerPicker = ({
    player,
    includeHuman = true,
    includeInvitation = true,
    onChange }: IProps) => {
    const [agents, setAgents] = useState(AgentDescriptionModel.empty());

    useEffect(() => {
        let getAllAgentsAsync = async () => {
            let result = await AgentDescriptionModel.getAllAgents();
            setAgents(result);
        };
        getAllAgentsAsync();
    }, []);

    const option = (condition: boolean, value: string) => condition && <option key={value} value={value} >{value}</option>;

    return (<div>
        <h2>{PieceModel.display(player)} Player Picker</h2>
        <select onChange={e => onChange(player, e.target.value)}>
            {option(includeHuman, GameSettingsModel.HumanAgentName)}
            {option(includeInvitation, GameSettingsModel.InvitationAgentName)}
            {agents.map(a => <option
                key={a.name}
                value={a.name}>
                {a.displayName}
            </option>)}
        </select>
    </div>);
};

export default PlayerPicker;