import React, { forwardRef } from 'react';
import '../sass/components/MenuButton.scss'

interface Props {
    menuOpen: boolean,
    handleMenuClick?: React.MouseEventHandler
}

export const MenuButton = forwardRef<HTMLButtonElement, Props>(({
    menuOpen = false,
    handleMenuClick = () => { }
}, ref) => {
    return (
        <button
            className="btn-menu"
            onClick={handleMenuClick}
            aria-pressed={menuOpen}
            ref={ref}
        >
            <svg
                className="svg-menu"
                width="38"
                height="38"
                viewBox="0 0 38 38"
                xmlns="https://www.w3.org/2000/svg">
                <title>Open</title>
                <path className="h t" d="M10.5 10l17 0" />
                <path d="M10.5 19l17 0" />
                <path className="h b" d="M10.5 28l17 0" />
                <path className="x" d="M19 10.5l0 17" />
            </svg>
            <span>Menu</span>
        </button>
    );
});

export default MenuButton;