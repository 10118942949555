import PieceModel from "./PieceModel";

interface SquareModel {
    name: string;
    piece: PieceModel;
    isValidMove: boolean;
    highlightSquare: boolean;
}

namespace SquareModel {
    export function matchSquareName(square: SquareModel, name: string): boolean {
        return square.name.toUpperCase() === name.toUpperCase();
    }
}

export default SquareModel;