import React from 'react';
import '../sass/components/ActionButton.scss'

interface IProps {
    children: string | JSX.Element;
    onClick: () => void;
}

export const ActionButton = ({ children, onClick }: IProps) => {
    return (
        <button
            className="actionButton"
            type="button"
            onClick={onClick}>
            {children}
        </button>
    );
};

export default ActionButton;
