import BoardDisplaySettingsModel from '../models/BoardDisplaySettingsModel';
import BoardModel from '../models/BoardModel';
import Service from '../utils/Service';
import '../sass/components/Board.scss';
import BoardRow from './BoardRow';
import PieceModel from '../models/PieceModel';
import ActionButton from './ActionButton';
import NewGameButton from './NewGameButton';

interface IProps {
    boardDisplaySettings?: BoardDisplaySettingsModel,
    board?: BoardModel,
    squareClickHandler?: (squareName: string) => void,
    currentPlayer: PieceModel,
    isGameOver?: boolean,
    isForcedPass?: boolean
};

export const Board = ({
    boardDisplaySettings = new BoardDisplaySettingsModel(),
    board = BoardModel.emptyBoard(),
    squareClickHandler = s => { },
    currentPlayer = PieceModel.None,
    isGameOver = false,
    isForcedPass = false
}: IProps) => {

    return (
        <>
            <div className={`boardComponent ${currentPlayer}ToPlay ${isGameOver ? "gameOver" : ""} ${isForcedPass ? "forcedPass" : ""}`}>
                <table >
                    {boardDisplaySettings.ShowTopHeader &&
                        <thead>
                            <tr>
                                {boardDisplaySettings.ShowLeftHeader && <th></th>}
                                {BoardModel.columnNames().map(col => <th key={col}>{col}</th>)}
                                {boardDisplaySettings.ShowRightHeader && <th></th>}
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {BoardModel.rowNumbers().map(row =>
                            <BoardRow
                                key={row}
                                boardDisplaySettings={boardDisplaySettings}
                                rowNumber={row}
                                board={board}
                                squareClickHandler={squareClickHandler!}
                            ></BoardRow>)}
                    </tbody>
                    {boardDisplaySettings.ShowBottomHeader && <tfoot>
                        <tr>
                            {boardDisplaySettings.ShowLeftHeader && <th></th>}
                            {BoardModel.columnNames().map(col => <th key={col}>{col}</th>)}
                            {boardDisplaySettings.ShowRightHeader && <th></th>}
                        </tr>
                    </tfoot>}
                </table>

                <div className="boardOverlay gameOverOverlay">
                    <div className="boardOverlayContent">
                        <h3>Game Over</h3>
                        <NewGameButton />
                    </div>
                </div>

                <div className="boardOverlay forcedPassOverlay">
                    <div className="boardOverlayContent">
                        <h3>No Moves</h3>
                        <ActionButton
                            onClick={() => squareClickHandler("PA")}>Pass</ActionButton>
                    </div>
                </div>
            </div>
            {boardDisplaySettings.ShowExportButton && <a target='_blank' rel='noreferrer' href={Service.apiUrl + 'Board/' + BoardModel.getBoardText(board) + '/0'}>Export</a>}
        </>);
};

export default Board;