import Service from '../utils/Service';
import BoardModel from './BoardModel';
import PieceModel from './PieceModel';
interface GameStateModel {
    identity: string;
    legalMoves: string[];
    currentPlayer: PieceModel;
    board: BoardModel;
    version: number;
}

namespace GameStateModel {

    export function isGameOver(gameState: GameStateModel): boolean {
        return !PieceModel.hasPiece(gameState.currentPlayer);
    }
    export function empty(): GameStateModel {
        return {
            identity: '',
            legalMoves: [],
            currentPlayer: PieceModel.None,
            board: BoardModel.emptyBoard(),
            version: 0
        };
    }
    export async function requestAgent(gameState: GameStateModel, piece: PieceModel, agentName: string) {
        await fetch(Service.agent.requestGame(
            agentName,
            gameState.identity,
            PieceModel.toCharString(piece)));
    }

    export async function fromJson(jsonPromise: Promise<any>) {

        let ret = await jsonPromise as GameStateModel;
        ret.currentPlayer = PieceModel.parse(ret.currentPlayer);//Normalize the casing of the current player
        return ret;
    }

    export function createNewGame(): Promise<GameStateModel> {
        let url = Service.game.createNewGame();
        return fetch(url, { method: 'PUT' })
            .then(r => fromJson(r.json()));
    }

    export function makeMove(gameId: string, move: string, player?: PieceModel): Promise<GameStateModel> {
        let url = Service.game.makeMove(gameId, move, player);
        return fetch(url, { method: 'PATCH' })
            .then(r => fromJson(r.json()));
    }

    export function loadGame(gameId: string): Promise<GameStateModel> {
        let url = Service.game.game(gameId);
        return loadGameByUrl(url);
    }

    function loadGameByUrl(url: string) {
        return fetch(url, { method: 'GET' })
            .then(r => {
                if (!r.ok) {
                    throw new Error("game state error")
                }
                return fromJson(r.json());
            });
    }

    export async function waitForGameUpdate(
        gameId: string,
        version: number,
        signal: AbortSignal | null
    ): Promise<GameStateModel | null> {
        try {
            let url = Service.game.gameVersion(gameId, version);
            while (true) {
                var result = await fetch(url, { method: 'GET', signal: signal });
                if (result.ok) {
                    return await fromJson(await result.json());
                }
                else if (result.status !== 408/*timeout*/) {
                    //real failure here 
                    throw new Error(`Unable to wait for game state ${gameId} version ${version}`);
                }
            }
        } catch (e) {
            if (e instanceof Error && e.name === 'AbortError') {
                return null;
            } else {
                throw e;
            }
        }
    }
}
export default GameStateModel;