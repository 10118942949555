import React from 'react';
import '../sass/components/ActionButtonList.scss'

interface IProps {
    children: JSX.Element[]
}

export const ActionButtonList = ({ children }: IProps) => {
    return (
        <ul className="actionButtonList">
            {children.map((child, index) => <li key={index}>{child}</li>)}
        </ul>
    );
};

export default ActionButtonList;
