export class BoardDisplaySettingsModel {

    public ShowTopHeader: boolean; //Show the A-H header above the board
    public ShowLeftHeader: boolean; //Show the 1-8 header to the left of the board
    public ShowRightHeader: boolean;// Show the 1-8 header to the right of the board
    public ShowBottomHeader: boolean;//Show the A-H header below the board
    public ShowExportButton: boolean;// Show a button to export the current board
    public ShowValidMoves: boolean;//Show a highlight on squares which are valid moves
    public HighlightLastMove: boolean;//Show an indication in the square of the last move made

    //TODO: use these
    public SquareColor: string; //Selects the color of the board
    public ShowMoveHistory: boolean;// Above each square, show the move number in which it was played
    public ShowMoveResultPreview: boolean;//Show a preview of the result of the move on mouseover
    public HighlightMousePosition: boolean;//Highlight the row and column of the mouse position

    constructor() {
        this.ShowTopHeader = true;
        this.ShowLeftHeader = true;
        this.ShowRightHeader = true;
        this.ShowBottomHeader = true;
        this.ShowExportButton = true;
        this.ShowValidMoves = true;
        this.HighlightLastMove = true;

        this.ShowMoveHistory = false;
        this.ShowMoveResultPreview = false;
        this.SquareColor = "green";
        this.HighlightMousePosition = false;
    }

    public Clone(): BoardDisplaySettingsModel {

        let ret = new BoardDisplaySettingsModel();
        Object.assign(ret, this);//safe only because there are no nested properties
        return ret;
    }

    public With(mutation: (s: BoardDisplaySettingsModel) => void) {
        let ret = this.Clone();
        mutation(ret);
        return ret;
    }
}

export default BoardDisplaySettingsModel;